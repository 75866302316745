import React from 'react'
import { Helmet } from 'react-helmet'
import { colors } from '$theme/legacy'
import { rhythm } from '$utils/typography'
import Layout from '../components/Layout'
const css = {
  main: {
    width: '100%',
    backgroundColor: colors.primary,
    padding: `0 10px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 0 auto ',
    color: 'white',
    maxWidth: 550,
  },
  header: {
    color: 'white',
    textAlign: 'center',
    maxWidth: '600px',
    margin: `${rhythm(2)} auto`,
    '& h1': {
      color: colors.lightDark,
      textTransform: 'uppercase',
      fontWeight: '300',
    },
  },
}

const p = `Thank you for contacting us. We will respond as quickly as humanly possible.
Sometimes our internet connection isn't the best of quality and will take a little longer to reply.
 Otherwise, you should hear from us very soon!`

function ThankYou() {
  return (
    <Layout>
      <div css={css.main}>
        <Helmet>
          <title>Thank You</title>
        </Helmet>
        <div css={css.container}>
          <div css={css.header}>
            <h1>Thank you</h1>
            <p>{p}</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou
